<template>
    <v-container class="pa-4 py-8">
        <div 
            v-if="$route.path == '/createcoverage'" 
            class="text-h5 font-weight-bold grey--text text--darken-2 mb-4"
            >
             <span><router-link :to="{name: 'ViewAllCoverage'}"><v-icon>mdi mdi-arrow-left</v-icon></router-link></span>
            <v-icon>mdi-new-box</v-icon>
            Create New ORMP <span v-if="this.$store.getters['useraccount/isAuthenticated'].department == 'RISK MANAGEMENT TEAM'">for {{this.$store.getters['logs/getDataOwner']}}</span>
        </div>
        <div 
            v-else
            class="text-h5 font-weight-bold grey--text text--darken-2 mb-4">
            <span><router-link :to="{name: 'ViewAllPRMPCoverage'}"><v-icon>mdi mdi-arrow-left</v-icon></router-link></span>
            <v-icon>mdi-window-closed</v-icon>
            Create New PRMP
        </div>

        <v-stepper
            v-model="e6"
            vertical
            v-if="$route.path == '/createcoverage'"
            >
             <v-stepper-step
                :complete="e6 > 1"
                step="1"
                >
                Reason for ORMP Creation
            </v-stepper-step>

            <v-stepper-content step="1">
                <v-row class="pl-6">
                    <v-radio-group
                        row
                        v-model="reasonForORMP"
                         >
                            <v-radio
                                label="Monthly Update"
                                value="Monthly Update"
                                >
                            </v-radio>
                            <v-radio
                                label="Risk Transpired"
                                value="Risk Transpired"
                                >
                            </v-radio>
                    </v-radio-group>
                </v-row>
                <v-row class="pl-4 pt-2">
                    <v-btn
                        :disabled="returnDoesReasonExist('ORMP')"
                        color="primary"
                        @click="e6 = 2"
                        >
                        Continue
                    </v-btn>
                </v-row>
            </v-stepper-content>
            <v-stepper-step
                :complete="e6 > 2"
                step="2"
                >
                Select a Date
            </v-stepper-step>

            <v-stepper-content step="2">
                <v-row class="pl-4">
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :return-value.sync="startDate"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="startDate"
                            label="Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            type="month"
                            v-model="startDate"
                            no-title
                            :min="getMin"
                           
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu1 = false"
                                >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu1.save(startDate)"
                                >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-row>

                <!-- <v-row class="pl-4">
                     <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :return-value.sync="endDate"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="endDate"
                            label="End Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            type="month"
                            v-model="endDate"
                            :allowed-dates="allowedDatesEnd"
                            no-title
                            scrollable
                            :min="getMin"
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu2 = false"
                                >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu2.save(endDate)"
                                >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-row> -->
                    <!-- <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :return-value.sync="startDate"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="startDate"
                            label="Start Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="startDate"
                            :allowed-dates="allowedDates"
                            no-title
                            scrollable
                            :min="getMin"
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu1 = false"
                                >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu1.save(startDate)"
                                >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-row>

                <v-row class="pl-4">
                     <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :return-value.sync="endDate"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="endDate"
                            label="End Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="endDate"
                            :allowed-dates="allowedDatesEnd"
                            no-title
                            scrollable
                            :min="getMin"
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu2 = false"
                                >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu2.save(endDate)"
                                >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu> -->
                <!-- </v-row> -->

                <v-row class="pt-4 pl-4">
                    <v-btn
                        :disabled="returnDoesDateExist('ORMP')"
                        color="primary"
                        @click="e6 += 1"
                        >
                        Continue
                    </v-btn>
                      <v-btn
                    class="ml-1"
                    @click="e6 -= 1"
                    >
                    Back
                </v-btn>
                </v-row>
            </v-stepper-content>

            <v-stepper-step
                :complete="e6 > 2"
                step="3"
                >
                Select ORMP Rows
            </v-stepper-step>
            <v-stepper-content
             step="3"
             v-if="this.ORMPs.length === 0"
             >
             <p>There are no ORMP's available to replicate.</p>
                 <v-btn
                    v-if="this.ORMPs.length === 0"
                    color="primary"
                    @click="saveDateRange"
                    >
                    Save
                </v-btn>
                <v-btn
                    class="ml-1"
                    @click="e6 -= 1"
                    >
                    Back
                </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3" v-else>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="returnRMPs('ORMPs')"
                    single-expand
                    show-expand
                    item-key=".key"
                    show-select
                    class="elevation-1"
                    >
                    <template v-slot:expanded-item="{ item }">
                        <td colspan="12">
                        <v-row>
                            <v-col cols="3"> 
                            <v-list-item-title><h4>Objectives</h4></v-list-item-title>
                            <v-list 
                                v-for="(cause, index) in item.Objectives"
                                :key="index"
                                ><v-list-item>{{ cause }}</v-list-item>
                            </v-list>
                            </v-col>
                            
                            <v-col cols="3"> 
                            <v-list-item-title><h4>Causes</h4></v-list-item-title>
                            <v-list 
                                v-for="(cause, index) in item.Causes"
                                :key="index"
                                ><v-list-item>{{index+1}} - {{ cause }}</v-list-item>
                            </v-list>
                            </v-col>
                            
                            <v-col cols="3">
                            <v-list-item-title><h4>Impacts</h4></v-list-item-title>
                                <v-list 
                                v-for="(impact, index) in item.Impacts"
                                :key="index"
                                ><v-list-item>{{ impact }}</v-list-item>
                            </v-list>
                            </v-col>
                            
                            <v-col cols="3">
                                <v-list-item-title><h4>Existing Risk Action for Causes</h4></v-list-item-title>
                                <div v-for="(riskaction, index) in item.RiskActions"
                                    :key="index">
                                    <v-list-item> 
                                    <v-col><span v-for="(cause, index) in riskaction.causes"
                                        :key="index">{{cause.value+1}}<span v-if="index != (riskaction.causes.length - 1)">,</span></span> 
                                    </v-col>
                                    <v-col>{{riskaction.text}}</v-col>
                                    </v-list-item>
                                </div>
                            </v-col>
                        </v-row>
                        </td>
                    </template>

                    <template v-slot:item.RiskTreatmentOwner="{ item }">
                        <td>
                            <div
                                v-for="(riskowner, index) in item.RiskTreatmentOwner"
                                :key="index">
                                <span>{{riskowner.RiskTreatmentOwner}}<span v-if="index != (item.RiskTreatmentOwner.length -1)">,</span></span>
                            </div>
                        </td>
                    </template>

                    <!-- <template v-slot:item.information="{ item }">
                        <v-chip 
                            color="green" 
                            text-color="white" 
                            class="mt-1" 
                            v-if="levelStatusTime(item)"
                            >{{levelStatusTime(item)}}
                        </v-chip>
                    </template> -->

                    <template v-slot:item.AffectedStakeholders="{ item }">
                        <td>
                        <div v-for="(stakeholder, index) in item.AffectedStakeholders"
                            :key="index">
                            <span>{{stakeholder.AffectedStakeholder}}<span v-if="index != (item.AffectedStakeholders.length -1)">,</span></span>
                        </div>
                        </td>
                    </template>

                    

                    <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="initialize"
                            >
                            Reset
                        </v-btn> 
                    </template>
                </v-data-table>

                <div class="mt-4">
                    <v-btn
                        color="primary"
                        @click="saveCoverage"
                        >
                        Save
                    </v-btn>
                    <v-btn
                        class="ml-1"
                        @click="e6 -= 1"
                        >
                        Back
                    </v-btn>
                </div>
            </v-stepper-content>
        </v-stepper>

        <v-stepper
            v-else
            v-model="e6"
            vertical
            >
             <v-stepper-step
                :complete="e6 > 1"
                step="1"
                >
                Reason for PRMP Creation
            </v-stepper-step>

            <v-stepper-content step="1">
                <v-row class="pl-6">
                    <v-radio-group
                        row
                        v-model="reasonForPRMP"
                         >
                            <v-radio
                                label="Monthly Update"
                                value="Monthly Update"
                                >
                            </v-radio>
                            <v-radio
                                label="Risk Transpired"
                                value="Risk Transpired"
                                >
                            </v-radio>
                    </v-radio-group>
                </v-row>
                <v-row class="pl-4 pt-2">
                    <v-btn
                        :disabled="returnDoesReasonExist('PRMP')"
                        color="primary"
                        @click="e6 = 2"
                        >
                        Continue
                    </v-btn>
                </v-row>
            </v-stepper-content>
            <v-stepper-step
                :complete="e6 > 2"
                step="2"
                >
                Select a Date
            </v-stepper-step>

            <v-stepper-content step="2">
                <v-row class="pl-4">
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :return-value.sync="startDatePRMP"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="startDate"
                            label="Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            type="month"
                            v-model="startDate"
                            no-title
                            :min="getMin"
                            :max="getMax"
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu1 = false"
                                >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu1.save(startDate)"
                                >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-row>

                <v-row class="pt-4 pl-4">
                    <v-btn
                        :disabled="returnDoesDateExist('PRMP')"
                        color="primary"
                        @click="e6 += 1"
                        >
                        Continue
                    </v-btn>
                      <v-btn
                    class="ml-1"
                    @click="e6 -= 1"
                    >
                    Back
                </v-btn>
                </v-row>
            </v-stepper-content>

            <v-stepper-step
                :complete="e6 > 2"
                step="3"
                >
                Select PRMP Rows
            </v-stepper-step>
            <v-stepper-content
             step="3"
             v-if="this.PRMPs.length === 0"
             >
             <p>There are no PRMP's available to replicate.</p>
                 <v-btn
                    v-if="this.PRMPs.length === 0"
                    color="primary"
                    @click="saveDateRange"
                    >
                    Save
                </v-btn>
                <v-btn
                    class="ml-1"
                    @click="e6 -= 1"
                    >
                    Back
                </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3" v-else>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="returnRMPs('PRMPs')"
                    single-expand
                    show-expand
                    item-key=".key"
                    show-select
                    class="elevation-1"
                    >
                    <template v-slot:expanded-item="{ item }">
                        <td colspan="12">
                        <v-row>
                            <v-col cols="3"> 
                            <v-list-item-title><h4>Objectives</h4></v-list-item-title>
                            <v-list 
                                v-for="(cause, index) in item.Objectives"
                                :key="index"
                                ><v-list-item>{{ cause }}</v-list-item>
                            </v-list>
                            </v-col>
                            
                            <v-col cols="3"> 
                            <v-list-item-title><h4>Causes</h4></v-list-item-title>
                            <v-list 
                                v-for="(cause, index) in item.Causes"
                                :key="index"
                                ><v-list-item>{{index+1}} - {{ cause }}</v-list-item>
                            </v-list>
                            </v-col>
                            
                            <v-col cols="3">
                            <v-list-item-title><h4>Impacts</h4></v-list-item-title>
                                <v-list 
                                v-for="(impact, index) in item.Impacts"
                                :key="index"
                                ><v-list-item>{{ impact }}</v-list-item>
                            </v-list>
                            </v-col>
                            
                            <v-col cols="3">
                                <v-list-item-title><h4>Existing Risk Action for Causes</h4></v-list-item-title>
                                <div v-for="(riskaction, index) in item.RiskActions"
                                    :key="index">
                                    <v-list-item> 
                                    <v-col><span v-for="(cause, index) in riskaction.causes"
                                        :key="index">{{cause.value+1}}<span v-if="index != (riskaction.causes.length - 1)">,</span></span> 
                                    </v-col>
                                    <v-col>{{riskaction.text}}</v-col>
                                    </v-list-item>
                                </div>
                            </v-col>
                        </v-row>
                        </td>
                    </template>

                    <template v-slot:item.RiskTreatmentOwner="{ item }">
                        <td>
                            <div
                                v-for="(riskowner, index) in item.RiskTreatmentOwner"
                                :key="index">
                                <span>{{riskowner.RiskTreatmentOwner}}<span v-if="index != (item.RiskTreatmentOwner.length -1)">,</span></span>
                            </div>
                        </td>
                    </template>

                    <template v-slot:item.AffectedStakeholders="{ item }">
                        <td>
                        <div v-for="(stakeholder, index) in item.AffectedStakeholders"
                            :key="index">
                            {{stakeholder.AffectedStakeholder}}
                        </div>
                        </td>
                    </template>

                    

                    <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="initialize"
                            >
                            Reset
                        </v-btn> 
                    </template>
                </v-data-table>

                <div class="mt-4">
                    <v-btn
                        color="primary"
                        @click="saveCoverage"
                        >
                        Save
                    </v-btn>
                    <v-btn
                        class="ml-1"
                        @click="e6 -= 1"
                        >
                        Back
                    </v-btn>
                </div>
            </v-stepper-content>
        </v-stepper>
    </v-container>
</template>

<script>
import moment from 'moment';
export default {
    data() {
        return {
            e6: 1,
            reasonForPRMP: '',
            coverageID: '',
            reasonForORMP: '',
            startDate: null,
            startDatePRMP: null,
            endDate: null,
            menu1: false,
            menu2: false,
            selected: [],
            headers: [
                { text: 'Risk Name', value: 'RiskName.RiskName' },
                {
                    text: 'Risk Classification',
                    align: 'start',
                    sortable: true,
                    value: 'RiskClassification.RiskClassification',
                },
                { text: 'Affected Stakeholders', value: 'AffectedStakeholders' },
                { text: 'Risk Treatment Owner', value: 'RiskTreatmentOwner' },
                { text: 'Likelihood', value: 'LikelihoodLevel.Level' },
                { text: 'Impact', value: 'ImpactLevel.Level' },
                { text: 'Impact Classification', value: 'ImpactClassification.name' },
                { text: 'Vulnerability', value: 'Vulnerability' },
                { text: 'Risk Strategy', value: 'RiskStrategy.RiskStrategy' },
                // { text: 'Information', value: 'information' },
            ],
        }
    },
    created() {
    this.initialize()
        console.log(this.$store.getters['logs/getDataOwner'],'data owner');         
    },

    firestore(){
      return {
        ORMPs: this.$db.collection('ORMP'),
        PRMPs: this.$db.collection('PRMP'),
        ORMPCoverage: this.$db.collection('ORMPCoverage'),
        ORMPLevels: this.$db.collection('ORMPLevels'),
        Departments: this.$db.collection('Departments'),
        AffectedStakeholder: this.$db.collection('AffectedStakeholder'),
      }
    },

    methods: {
         returnRMPs(plan){
        let before = this[plan]
        let self = this

        let user = self.$store.getters['useraccount/isAuthenticated']
        
       
            return self.$lodash.uniqBy(before.filter(a=>{
                return a.Department == user.department
            }), e=>{
                return e.RiskName.RiskName
            })          
        
      },
         returnIsTranspired(){
            if(this.reasonForORMP == ''){
                return ''
            } else if(this.reasonForORMP == 'Risk Transpired'){
                return true
            } else if(this.reasonForORMP == 'Monthly Update'){
                return false
            }
        },
        returnDoesReasonExist(type){
            if(type == 'ORMP'){
                if(this.reasonForORMP == ''){
                    return true
                } else {
                    return false
                }
            } else {
                if(this.reasonForPRMP == ''){
                    return true
                } else {
                    return false
                }
            }
        },
        returnDoesDateExist(type){
            if(type == 'ORMP'){
                if(this.startDate == null){
                    return true
                } else {
                    return false
                }
            } else {
                if(this.startDatePRMP == null){
                    return true
                } else {
                    return false
                }
            }
        },
        initialize () {
            this.desserts = this.ORMPs.filter(a=>{
            return this.deptFilter == a.Department
            })
        },
        async saveDateRange() {
            if(this.$route.path == '/createcoverage'){
                let data = {
                    startDate: this.startDate,
                    // endDate: this.endDate,
                    CreatedAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
                    CreatedBy: this.$store.getters['useraccount/isAuthenticated'].email,
                    Department: this.$store.getters['useraccount/isAuthenticated'].department == 'RISK MANAGEMENT TEAM' ? this.$store.getters['logs/getDataOwner'] : this.$store.getters['useraccount/isAuthenticated'].department,
                    isTranspired: this.returnIsTranspired(),
                    isApproved: 'Pending',
                    approvedBy: ''
                }
            console.log(data)
            let self = this
             const confirm = await this.$dialog.confirm({
                text: `Do you really want to save this new ORMP Coverage?`,
                title: `Confirm New ORMP Coverage`
            })
            if(confirm){
                console.log(data)
                self.$db.collection('ORMPCoverage').add(data).then(async (doc)=>{
                    let id = doc.id
                    await self.$store.dispatch('logs/createNotifs', {collection:'ORMPCoverage',documentKey:id,module: 'ORMP',action: 'Added New ORMP Coverage'})  
                    self.$dialog.notify.success(`Added New ORMP Coverage`, {
                        position: 'bottom-right',
                        timeout: 3000
                      })      
                      self.$router.push('/viewallormp/'+id)      
                    })    
                }
            } else {
                let data = {
                startDate: this.startDate,
                // endDate: this.endDate,
                CreatedAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
                CreatedBy: this.$store.getters['useraccount/isAuthenticated'].email,
                Department: this.$store.getters['useraccount/isAuthenticated'].department == 'RISK MANAGEMENT TEAM' ? this.$store.getters['logs/getDataOwner'] : this.$store.getters['useraccount/isAuthenticated'].department,
                Product: this.$route.params.Product,
                isTranspired: this.returnIsTranspired(),
                isApproved: 'Pending',
                approvedBy: ''
            }
            let self = this
             const confirm = await this.$dialog.confirm({
                text: `Do you really want to save this new PRMP Coverage?`,
                title: `Confirm New PRMP Coverage`
            })
            if(confirm){
                console.log(data)
                self.$db.collection('PRMPCoverage').add(data).then(async (doc)=>{
                    let id = doc.id
                    await self.$store.dispatch('logs/createNotifs', {collection:'PRMPCoverage',documentKey:id,module: 'PRMP',action: 'Added New PRMP Coverage'})  
                    self.$dialog.notify.success(`Added New PRMP Coverage`, {
                        position: 'bottom-right',
                        timeout: 3000
                      })      
                      self.$router.push('/viewallprmp/'+id)      
                    })    
                }
            }
        },
        async saveCoverage() {
            if(this.$route.path == '/createcoverage'){
                  let data = {
                startDate: this.startDate,
                // endDate: this.endDate,
                CreatedAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
                CreatedBy: this.$store.getters['useraccount/isAuthenticated'].email,
                Department: this.$store.getters['useraccount/isAuthenticated'].department == 'RISK MANAGEMENT TEAM' ? this.$store.getters['logs/getDataOwner'] : this.$store.getters['useraccount/isAuthenticated'].department,
                isTranspired: this.returnIsTranspired(),
                isApproved: 'Pending',
                approvedBy: ''
            }
            let self = this
             const confirm = await this.$dialog.confirm({
                text: `Do you really want to save this new ORMP Coverage?`,
                title: `Confirm New ORMP Coverage`
            })

            if(confirm){
                console.log(data)
                  self.$db.collection('ORMPCoverage').add(data)
                 .then(async(doc)=>{
                    let id = doc.id
                    await self.$store.dispatch('logs/createNotifs', {collection:'ORMPCoverage',documentKey:id,module: 'ORMP',action: 'Added New ORMP Coverage'}) 
                    this.coverageID = doc.id
                    let selected = this.selected
                    selected.forEach(e => {
                        let x = {...e}
                        delete x['.key']
                        x.CoverageId = this.coverageID
                        x.CreatedAt = this.$firebase.firestore.FieldValue.serverTimestamp()
                        x.CreatedBy = this.$store.getters['useraccount/isAuthenticated'].email
                        x.Department = this.$store.getters['useraccount/isAuthenticated'].department == 'RISK MANAGEMENT TEAM' ? this.$store.getters['logs/getDataOwner'] : this.$store.getters['useraccount/isAuthenticated'].department

                     this.$db.collection('ORMP').add(x)
                    .then(async(doc) => {
                       let y = {...x}
                        let ORMPId = doc.id 
                        await self.$store.dispatch('logs/createNotifs', {collection:'ORMP',documentKey:ORMPId,module: 'ORMP',action: 'Added New ORMP'}) 
                     
                           y.ORMPId = ORMPId
                           y.RiskManagementLevel = 'Inherent'
                        this.$db.collection('ORMPLevels').add(y).then(async(doc)=> {
                            let levelID = doc.id
                            await self.$store.dispatch('logs/createNotifs', {collection:'ORMPLevels',documentKey:levelID,module: 'ORMP',action: 'Added New ORMP Inherent Level'}) 

                            self.$dialog.notify.success(`Added New ORMP`, {
                            position: 'bottom-right',
                            timeout: 3000
                                })  
                            })
                        })                   
                    })  
                    self.$dialog.notify.success(`Added New ORMP Coverage`, {
                            position: 'bottom-right',
                            timeout: 3000
                    })
                    self.$router.push('/viewallormp/' + this.coverageID)
                    this.selected = []
                    this.coverageID = ''
                 })
            }
                
            } else {
                let data = {
                startDate: this.startDate,
                // endDate: this.endDate,
                CreatedAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
                CreatedBy: this.$store.getters['useraccount/isAuthenticated'].email,
                Department: this.$store.getters['useraccount/isAuthenticated'].department == 'RISK MANAGEMENT TEAM' ? this.$store.getters['logs/getDataOwner'] : this.$store.getters['useraccount/isAuthenticated'].department,
                Product: this.$route.params.Product,
                isTranspired: this.returnIsTranspired(),
                isApproved: 'Pending',
                approvedBy: ''
            }
            let self = this
             const confirm = await this.$dialog.confirm({
                text: `Do you really want to save this new PRMP Coverage?`,
                title: `Confirm New PRMP Coverage`
            })

            if(confirm){
                console.log(data)
                  self.$db.collection('PRMPCoverage').add(data)
                 .then(async(doc)=>{
                    let id = doc.id
                    await self.$store.dispatch('logs/createNotifs', {collection:'PRMPCoverage',documentKey:id,module: 'PRMP',action: 'Added New PRMP Coverage'}) 
                    this.coverageID = doc.id
                    let selected = this.selected
                    selected.forEach(e => {
                        let x = {...e}
                        delete x['.key']
                        x.CoverageId = this.coverageID
                        x.CreatedAt = this.$firebase.firestore.FieldValue.serverTimestamp()
                        x.CreatedBy = this.$store.getters['useraccount/isAuthenticated'].email
                        x.Department = this.$store.getters['useraccount/isAuthenticated'].department == 'RISK MANAGEMENT TEAM' ? this.$store.getters['logs/getDataOwner'] : this.$store.getters['useraccount/isAuthenticated'].department,
                        x.Product = this.$route.params.Product,

                     this.$db.collection('PRMP').add(x)
                    .then(async(doc) => {
                       let y = {...x}
                        let PRMPId = doc.id 
                        await self.$store.dispatch('logs/createNotifs', {collection:'PRMP',documentKey:PRMPId,module: 'PRMP',action: 'Added New PRMP'}) 
                     
                           y.PRMPId = PRMPId
                           y.RiskManagementLevel = 'Inherent'
                        this.$db.collection('PRMPLevels').add(y).then(async(doc)=> {
                            let levelID = doc.id
                            await self.$store.dispatch('logs/createNotifs', {collection:'PRMPLevels',documentKey:levelID,module: 'PRMP',action: 'Added New PRMP Inherent Level'}) 

                            self.$dialog.notify.success(`Added New PRMP`, {
                            position: 'bottom-right',
                            timeout: 3000
                                })  
                            })
                        })                   
                    })  
                    self.$dialog.notify.success(`Added New PRMP Coverage`, {
                            position: 'bottom-right',
                            timeout: 3000
                    })
                    self.$router.push('/viewallprmp/' + this.coverageID)
                    this.selected = []
                    this.coverageID = ''
                 })
            }
            }
        },
        // setDefaultDate(){
        //     this.startDate = this.getMin
        //     this.endDate = this.getMin
        // },

        levelStatusTime(item) {
        let hello = []
        let filter = this.ORMPLevels.filter(e => {
         return e.ORMPId === item['.key'] 
        })
          filter.forEach(e=> {
            hello.push({RiskManagementLevel: e.RiskManagementLevel, CreatedAt: e.CreatedAt})
          })
          let order = this.$lodash.orderBy(hello, ['CreatedAt'], ['desc'])
          let mapped = order.map(e=> {
            return e.RiskManagementLevel + " " + 'created on' + " " + moment(e.CreatedAt.toDate()).format(' MMMM DD, YYYY / h:mm A')
          })
          return mapped[0]
        },
      
        isSameOrAfterAndBefore(date,start,end){
          return this.$moment(date).isSameOrAfter(start) && this.$moment(date).isSameOrBefore(end) 
         },

        isDateinORMPCoverage(value){
          console.log(value,value);
          let cov = this.ORMPCoverage.filter(a=>{
              let ORMPstartDate = a.startDate
              let ORMPendDate = a.endDate
              return this.$moment(value).isBetween(ORMPstartDate,ORMPendDate) || this.isSameOrAfterAndBefore(value,ORMPstartDate,ORMPendDate)
          })
          return cov.length > 0
        },
        allowedDates(val) {
          return !this.isDateinORMPCoverage(val)
        },
        allowedDatesEnd(val) {
          return !this.isDateinORMPCoverage(val) && val > this.startDate
        },
    },
    computed: {
       
        // getMin() {
        //     var d = new Date(new Date().getFullYear() + 1, 0, 2);
        //     return d.toISOString().substring(0, 10)
        // },
          getMin() {
            // var d = new Date(new Date().getFullYear() + 1, 0, 2);
            var d = new Date(new Date().getFullYear(), 0, 2);
            return d.toISOString().substring(0, 7);
        },

        getMax() {
            var d = new Date();
            console.log(d.toISOString().substring(0, 7))
            return d.toISOString().substring(0, 10)
        },
    }
}
</script>
<style scoped>
a:link {
  text-decoration: none;
}
</style>
